import Tracker from '../tracker';

const viewport = Tracker();

class ContentCarouselClass {
  constructor(config = {}, init = true) {
    this.config = _.defaults(config, {
      innerClass: 'carousel-content-inner',
      itemClass: 'content-carousel-item',
      nextButtonClass: 'content-carousel-next',
      previousButtonClass: 'content-carousel-previous',
    });

    if (init) {
      this._initViewport();
      this._init();
    }
  }

  _init() {
    this.animating = false;
    this.carouselInner = this.config.element.querySelector(
      `.${this.config.innerClass}`
    );
    this.currentItem = 0;
    this.moveModifier = parseInt(
      this.config.element.getAttribute('data-move-modifier'),
      10
    );
    this.nextButton = this.config.element.querySelector(
      `.${this.config.nextButtonClass}`
    );
    this.position = 0;
    this.previousButton = this.config.element.querySelector(
      `.${this.config.previousButtonClass}`
    );
    this._render();
  }

  _initViewport() {
    this.device = viewport.getDevice();
    // this.size = viewport.getWidth();
    Tracker(this._trackSize.bind(this));
  }

  _render() {
    this._getItems();
    this._addNextListener();
    this._addPreviousListener();
    this._setCarouselInnerClass();
    this._toggleDisabledClass();
    this._checkAutoPlay();
  }

  _addFocusListeners() {
    this.config.element.addEventListener(
      'mouseenter',
      this._stopAutoPlay.bind(this),
      false
    );
    this.config.element.addEventListener(
      'mouseleave',
      this._restartTimer.bind(this),
      false
    );
  }

  _addNextListener() {
    this.nextButton.addEventListener('click', this._next.bind(this), false);
  }

  _addPreviousListener() {
    this.previousButton.addEventListener(
      'click',
      this._previous.bind(this),
      false
    );
  }

  _checkAutoPlay() {
    const autoPlay = this.config.element.getAttribute('data-autoplay');

    if (autoPlay === 'true') {
      this._addFocusListeners();
      this._restartTimer();
    }
  }

  _getItems() {
    this.items = this.config.element.querySelectorAll(
      `.${this.config.itemClass}`
    );

    this._setVisibleItems();
  }

  _getItemWidth() {
    const width = this.config.element.clientWidth;
    const itemWidth = (width / this.visible[this.device] / width) * 100;

    return itemWidth;
  }

  _getVisible() {
    const visible = this.visible[this.device];

    return visible;
  }

  _moveCarousel(direction, adjusted = false, moveAmount = null) {
    const position = this._setPosition(direction, adjusted, moveAmount);

    this.position += position;
    this.carouselInner.style.left = `${this.position}%`;
    this._toggleDisabledClass();
  }

  _next(e) {
    e.preventDefault();

    const visible = this._getVisible();

    if (
      this.currentItem < this.items.length &&
      this.currentItem + visible < this.items.length
    ) {
      if (
        this.moveModifier &&
        !(this.items.length - (this.currentItem + visible) < visible)
      ) {
        this.currentItem += this.moveModifier;

        this._moveCarousel('next', true, this.moveModifier);
      } else if (this.items.length - (this.currentItem + visible) < visible) {
        const moveAmount = this.items.length - (this.currentItem + visible);

        this.currentItem += moveAmount;

        this._moveCarousel('next', true, moveAmount);
      } else {
        this.currentItem += visible;

        this._moveCarousel('next');
      }
    }
  }

  _previous(e) {
    e.preventDefault();

    const visible = this._getVisible();

    if (this.currentItem > 0) {
      if (this.moveModifier && !(this.currentItem - visible < visible)) {
        this.currentItem -= this.moveModifier;

        this._moveCarousel('previous', true, this.moveModifier);
      } else if (this.currentItem - visible < visible) {
        const moveAmount = this.currentItem;

        this.currentItem -= moveAmount;
        this._moveCarousel('previous', true, moveAmount);
      } else {
        this.currentItem -= visible;
        this._moveCarousel('previous');
      }
    }
  }

  _resize() {
    const itemWidth = this._getItemWidth();
    const visible = this._getVisible();

    if (this.currentItem + visible > this.items.length) {
      const overBy = this.currentItem + visible - this.items.length;
      this.currentItem -= overBy;
    }

    this.position = parseFloat(`-${this.currentItem * itemWidth}`);
    this.carouselInner.style.left = `${this.position}%`;

    this._setCarouselInnerClass();
    this._toggleDisabledClass();
  }

  _restartTimer() {
    if (this.items.length > 1) {
      this._stopAutoPlay();
      this.timer = window.setInterval(this._startAutoPlay.bind(this), 5000);
    }
  }

  _setCarouselInnerClass() {
    const { className } = this.carouselInner;
    const visible = this._getVisible();

    if (_.includes(className, 'showing')) {
      this.carouselInner.className = className.replace(
        /(?:^|\s)showing-[0-9]*/g,
        ` showing-${visible}`
      );
    } else {
      this.carouselInner.className += ` showing-${visible}`;
    }
  }

  _setPosition(direction, adjusted, moveAmount) {
    let position;
    const itemWidth = this._getItemWidth();
    const visible = this._getVisible();

    if (adjusted) {
      position = parseFloat(
        direction === 'next'
          ? `-${itemWidth * moveAmount}`
          : itemWidth * moveAmount
      );
    } else {
      position = parseFloat(
        direction === 'next' ? `-${itemWidth * visible}` : itemWidth * visible
      );
    }

    return position;
  }

  _setVisibleItems() {
    const visibleMobile = this.config.element.getAttribute(
      'data-visible-mobile'
    );
    const visibleTablet = this.config.element.getAttribute(
      'data-visible-tablet'
    );
    const visibleDesktop = this.config.element.getAttribute(
      'data-visible-desktop'
    );

    this.visible = {
      mobile:
        visibleMobile <= this.items.length ? parseInt(visibleMobile, 10) : 1,
      tablet:
        visibleTablet <= this.items.length ? parseInt(visibleTablet, 10) : 2,
      desktop:
        visibleDesktop <= this.items.length
          ? parseInt(visibleDesktop, 10)
          : this.items.length,
    };
  }

  _startAutoPlay() {
    const visible = this._getVisible();

    if (
      this.currentItem < this.items.length &&
      this.currentItem + visible < this.items.length
    ) {
      if (this.moveModifier) {
        this.currentItem += this.moveModifier;

        this._moveCarousel('next', true, this.moveModifier);
      } else if (this.items.length - (this.currentItem + visible) < visible) {
        const moveAmount = this.items.length - (this.currentItem + visible);

        this.currentItem += moveAmount;

        this._moveCarousel('next', true, moveAmount);
      } else {
        this.currentItem += visible;

        this._moveCarousel('next');
      }
    }
  }

  _stopAutoPlay() {
    if (!_.isUndefined(this.timer)) {
      window.clearInterval(this.timer);

      this.timer = undefined;
    }
  }

  _toggleDisabledClass() {
    const nextButtonClassName = this.nextButton.className;
    const previousButtonClassName = this.previousButton.className;
    const visible = this._getVisible();

    if (
      this.currentItem < this.items.length &&
      this.currentItem + visible < this.items.length
    ) {
      if (_.includes(this.nextButton.className, 'disabled')) {
        this.nextButton.className = nextButtonClassName.replace(
          /(?:^|\s)disabled(?!\S)/g,
          ''
        );
      }
    } else if (!_.includes(this.nextButton.className, 'disabled')) {
      this.nextButton.className += ' disabled';
    }

    if (this.currentItem > 0) {
      if (_.includes(this.previousButton.className, 'disabled')) {
        this.previousButton.className = previousButtonClassName.replace(
          /(?:^|\s)disabled(?!\S)/g,
          ''
        );
      }
    } else if (!_.includes(this.previousButton.className, 'disabled')) {
      this.previousButton.className += ' disabled';
    }
  }

  _trackSize(device) {
    if (this.device !== device) {
      this.device = device;
      this._resize();
    }

    // this.size = size;
  }
}

export default ContentCarouselClass;
/* eslint-enable */
