export default () => {
  const primaryNav = document.getElementById('primary-navigation');
  const hamburger = document.getElementById('header-menu');

  // Needs catch statement in case not there
  if (!primaryNav || hamburger.length === 0) return;

  hamburger.addEventListener('click', () => {
    primaryNav.classList.toggle('open-nav');
  });
};
