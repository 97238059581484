import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="courses-filter"
export default class extends Controller {

  static targets = ['submit', 'options']

  connect() {
    this.optionsTarget.addEventListener('change', () => {
      this.submitTarget.click();
    })
  }
}
