export default () => {
  const navigationList = [
    ...document.querySelectorAll('.header-nav-list__item '),
  ].filter((item) => item.querySelector('.header-nav-sub-list') !== null);

  navigationList.forEach((item) => {
    item.addEventListener('click', () => {
      let subNav = item.querySelector('.header-nav-sub-list');
      if (subNav.style.display === '' || subNav.style.display === 'none') {
        subNav.style.display = 'block';
      } else {
        subNav.style.display = 'none';
      }
    });
  });

  if (window.innerWidth < 1200) {
    let navItemSubList = [
      ...document.querySelectorAll('.header-nav-list__item'),
    ];

    navItemSubList.forEach((el) => {
      if (el.children.length > 1) {
        el.children[0].removeAttribute('href');
        el.children[0].textContent += ' ⌄';
      }
    });
  }
};
