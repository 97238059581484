import { Controller } from "@hotwired/stimulus"
import Pikaday from 'pikaday'

// Connects to data-controller="pikaday"
export default class extends Controller {
  connect() {
    this.element.autocomplete = 'off';
    const picker = new Pikaday({
      field: this.element,
      firstDay: 1,
      // format: 'DD/MM/YYYY',
      dateLocale:'en-GB',
      dateOptions: {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
      toString(date, format) {
        return date.toLocaleDateString(this.dateLocale, this.dateOptions);
      }
    });
  }
}
