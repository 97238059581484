import { Controller } from "@hotwired/stimulus"
import { CountUp } from "countup.js";

// Connects to data-controller="count-up"
export default class extends Controller {
  connect() {
    let number = this.element.getAttribute('data-number')
    const options = {
      enableScrollSpy: true
    }
    const countUp = new CountUp(this.element, number, options);
    countUp.start();
    
  }
}
