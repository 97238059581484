import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contact-modal"
export default class extends Controller {
  
  connect() {
    const buttons = document.querySelectorAll('.contact-modal-open');
    buttons.forEach(button => {
      button.addEventListener('click', () => {
        this.toggle();
      })
    });
    const modalFull = document.querySelector('.contact-modal');
    modalFull.addEventListener('click', (e) => {
      if(e.target.classList.contains('contact-modal')){
        this.toggle();
      }
    })
  }
  
  toggle() {
    this.element.classList.toggle('open');
  }
}
