// import './font_observer';
import Carousel from '@morsedigital/vanilla-carousel';
import Hamburger from './hamburger';
import MobileNav from './mobile_navigation';
import ImageGallery from './image_gallery';
import Rails from '@rails/ujs';

import {
  EventListener,
  Cookiebar,
} from '@morsedigital/morse-utils';

(() => {
  Rails.start();
})();

(() => {
  const modules = [
    Cookiebar('cookie-prompt'), // Should be id of Cookie prompt DOM Node
  ];
  EventListener(modules);
})();

(() => {
  const contentCarousels = [...document.querySelectorAll('.content-carousel')];
  if (contentCarousels.length > 0) {
    import(
      /* webpackChunkName: "content_carousel" */ './content_carousel'
    ).then(({ default: ContentCarousel }) => {
      contentCarousels.forEach(
        (contentCarousel) =>
          new ContentCarousel({
            element: contentCarousel,
            innerClass: 'content-carousel-inner',
            itemClass: 'content-carousel-item',
          })
      );
    });
  }
})();

(() => {
  Carousel();
})();

(() => {
  Hamburger();
})();

(() => {
  MobileNav();
})();

(() => {
  ImageGallery();
})();
