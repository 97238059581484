import ViewportDetect from '@djforth/viewport-detection-fp';

const newBreakpoints = {
  mobile: { min: 0, max: 767 },
  tablet: { min: 768, max: 1023 },
  desktop: { min: 1024, max: null },
};

/*
////// This Tracks for viewport (screen) changes //////
*/

const vpDetect = ViewportDetect(newBreakpoints);
vpDetect.track();

export default (cb) => {
  if (cb) {
    vpDetect.addCallback(cb);
    cb(vpDetect.getDevice(), vpDetect.getWidth(), vpDetect.touchAvailable());
  }

  return vpDetect;
};
