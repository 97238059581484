import { Controller } from "@hotwired/stimulus"
import Typed from 'typed.js';

// Connects to data-controller="type-writer"
export default class extends Controller {
  connect() {

    let first = this.element.dataset.typeFirst;
    let second = this.element.dataset.typeSecond;
    let third = this.element.dataset.typeThird;
    let fourth = this.element.dataset.typeFourth;

    const typed = new Typed(this.element, {
      strings: [first, second, third, fourth],
      typeSpeed: 50,
      showCursor: false,
    });
  }
}
