import Splide from '@splidejs/splide';

export default () => {
  if (document.querySelector('.splide')) {

    var splide = new Splide( '.splide', {
      type: 'loop',
      perPage: 3,
      perMove: 1,
      autoplay: true,
      arrows: false,
      pagination: false,
      speed:2000,
      interval:4000,
      autoWidth:true,
      pauseOnHover:false,
    });    
    splide.mount();
  }
};
